<template>
  <div class="realName">
    <div class="realTitle">
      实名认证
      <img @click="goBack()" src="../assets/back.png" alt="" />
    </div>
    <div class="realInfo">
      <div class="name">
        <div class="left">姓名</div>
        <input
          v-if="!realTF"
          v-model="value"
          placeholder="请输入真实姓名"
          type="text"
        />
        <input
          v-if="realTF"
          v-model="value"
          readonly
          placeholder="请输入真实姓名"
          type="text"
        />
      </div>
      <div class="code">
        <div class="left">身份证号</div>
        <input
          v-if="!realTF"
          v-model="value1"
          placeholder="请输入身份证号"
          type="text"
        />
        <input
          v-if="realTF"
          readonly
          v-model="value1"
          placeholder="请输入身份证号"
          type="text"
        />
      </div>
    </div>
    <div v-if="!realTF" @click="postUserAuthData()" class="submit">提交</div>
    <div v-if="realTF" class="submit">已认证</div>
  </div>
</template>
<script>
import { postUserAuth } from "../api/person.js";
import { Toast } from "@nutui/nutui";

export default {
  data() {
    return {
      value: "",
      value1: "",
      realTF: false,
    };
  },
  setup() {},
  mounted() {
    if (this.$route.query.real == 1) {
      this.realTF = true;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    postUserAuthData() {
      if (this.check_id_card(this.value1)) {
        let params = {
          name: this.value,
          card: this.value1,
        };
        postUserAuth(params).then((res) => {
          Toast.text(res.msg);
          if (res.code == 1) {
            this.$router.go(-1);
          }
        });
      } else {
        Toast.text("请输入正确身份证号码");
      }
    },
    // 身份证校验
    check_id_card(code) {
      code = code.toUpperCase();

      // 前2位城市码
      let city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 ",
      };
      // 加权因子
      let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      // 校验位
      let parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
      // 身份证简单正则
      let Reg =
        /^\d{6}(18|19|20)?\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      // 身份证号码数组
      let arr_code = code.split("");

      // 校验编码为空，简单正则，城市编码
      if (
        !code ||
        !Reg.test(code) ||
        !city[code.substr(0, 2)] ||
        code.length !== 18
      ) {
        return false;
      }

      // 校验18位身份证需要验证最后一位校验位
      //∑(ai×Wi)(mod 11)
      let sum = 0;
      for (let i = 0; i < 17; i++) {
        sum += arr_code[i] * factor[i];
      }
      if (parity[sum % 11] != arr_code[17]) {
        return false;
      }

      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.realName {
  .realTitle {
    height: 40px;
    background: #ffffff;
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 16px;
      margin: auto;
    }
  }
  .realInfo {
    margin-top: 5px;
    background-color: #ffffff;
    padding: 0 22px;
    .name,
    .code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ececec;
      .left {
        font-weight: bold;
        width: 65px;
        flex-shrink: 0;
        font-size: 14px;
        color: #333333;
      }
      input {
        flex: 1;
        text-align: right;
        height: 50px;
        font-size: 14px;
      }
    }
    .code {
      border: 0;
    }
  }
  .submit {
    width: 345px;
    margin: 0 auto;
    height: 45px;
    background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
    border-radius: 25px;
    font-size: 15px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    line-height: 45px;
    margin-top: 35px;
  }
}
</style>